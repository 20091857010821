/** The categories of measurements */
import { MeasurementType } from '../MeasurementType';
import { GraphType, TimePeriod } from './GraphEnums';
import { ChartType } from 'chart.js';

export enum MeasurementCategory {
  MEDICAL_MEASUREMENTS     = 'MEDICAL_MEASUREMENTS',
  LIFESTYLE_MEASUREMENTS   = 'LIFESTYLE_MEASUREMENTS',
  PHYSICAL_MEASUREMENTS    = 'PHYSICAL_MEASUREMENTS',
  BLOOD_MEASUREMENTS       = 'BLOOD_MEASUREMENTS',
  MENTAL_MEASUREMENTS      = 'MENTAL_MEASUREMENTS',
  CUSTOM_MEASUREMENTS      = 'CUSTOM_MEASUREMENTS',
  NONE                     = 'NONE'
}

export enum MeasurementCategoryShort {
  NONE = 'NONE',
  MEDICAL = 'MEDICAL',
  LIFESTYLE = 'LIFESTYLE',
  PHYSICAL = 'PHYSICAL',
  BLOOD = 'BLOOD',
  MENTAL = 'MENTAL',
  CUSTOM = 'CUSTOM'
}

export const MEASUREMENT_CATEGORIES: MeasurementCategory[] = [
  MeasurementCategory.LIFESTYLE_MEASUREMENTS,
  MeasurementCategory.MEDICAL_MEASUREMENTS,
  MeasurementCategory.BLOOD_MEASUREMENTS,
  MeasurementCategory.PHYSICAL_MEASUREMENTS,
  MeasurementCategory.MENTAL_MEASUREMENTS,
  MeasurementCategory.CUSTOM_MEASUREMENTS
];

/** The type of measurement that determines quality **/
export enum MeasurementQualityType {
  HIGHER_IS_BETTER = 'HIGHER_IS_BETTER',
  MIDDLE_IS_BETTER = 'MIDDLE_IS_BETTER',
  LOWER_IS_BETTER = 'LOWER_IS_BETTER',
}

export const RelativeMeasurementQualityType: {[index: string]: string} = {
  HIGHER_IS_BETTER: 'MORE',
  MIDDLE_IS_BETTER: 'todo: determine',
  LOWER_IS_BETTER: 'LESS'
};

// 'measurement quality'
export enum MeasurementLimitIndication {
  NONE = 'None',
  BEST = 'Best',
  BETTER = 'Better',
  MIDDLE = 'Middle',
  WORSE = 'Worse',
  WORST = 'Worst',
}

export enum MeasurementHistoryItemType {
  None = 'None',
  MeasuredValue = 'MeasuredValue',
  Note = 'Note'
}

export const MEASUREMENT_LIMIT_INDICATIONS: MeasurementLimitIndication[] = [
  MeasurementLimitIndication.NONE,
  MeasurementLimitIndication.BEST,
  MeasurementLimitIndication.BETTER,
  MeasurementLimitIndication.MIDDLE,
  MeasurementLimitIndication.WORSE,
  MeasurementLimitIndication.WORST,
];

export const MEASUREMENT_QUALITIES_BY_QUALITY_TYPE = {
  HIGHER_IS_BETTER: [
    'BEST',
    'BETTER',
    'TARGET_VALUE',
    'LOW',
    'TOO_LOW',
  ],
  MIDDLE_IS_BETTER: [
    'TOO_HIGH',
    'HIGH',
    'TARGET_VALUE',
    'LOW',
    'TOO_LOW',
  ],
  LOWER_IS_BETTER: [
    'TOO_HIGH',
    'HIGH',
    'TARGET_VALUE',
    'BETTER',
    'BEST',
  ],
};

export enum MeasurementProgressionInterpretation {
  NONE = 'None',
  OPTIMAL = 'Optimal',
  IMPROVEMENT = 'Improvement',
  KEEP_GOING = 'KeepGoing',
  STILL_GOOD = 'StillGood',
  COULD_BE_BETTER = 'CouldBeBetter',
  SHOULD_BE_BETTER = 'ShouldBeBetter',
  GOOD = 'Good',
  DETERIORATING = 'Deteriorating',
  DEMANDS_ATTENTION = 'DemandsAttention',
}

export enum MeasurementProgressionIndication {
  NONE = 'None',
  BEST = 'Best',
  MIDDLE = 'Middle',
  WORST = 'Worst',
}

/** Used for assigning 'progress' by comparing latest measurement with the one before **/
export enum MeasurementProgressionType {
  NONE = 'None',
  RISING = 'Rising',
  EQUAL = 'Equal',
  DECLINING = 'Declining',
}

export enum MeasurementSource {
  MANUALLY = 'MANUALLY',
  WEARABLE = 'WEARABLE',
  HEALTHCARE_PROVIDER = 'HEALTHCARE_PROVIDER',
}

export enum MoodId {
  GREAT = 'Great',
  HAPPY = 'Happy',
  GOOD = 'Good',
  NORMAL = 'Normal',
  OKAY = 'Okay',
  NOT_GOOD = 'NotGood',
  BAD = 'Bad',
  AWFUL = 'Awful',
  NONE = 'None'
}

export interface Mood {
  id: string;
  text: string;
  emoji: string;
}

// Measurement model as saved and returned in API
export interface SavedMeasurement {
  id: string;
  dateTime: string;
  measurementType: MeasurementIdEnum;
  note: SavedMeasurementNote;
  value: number;
}

export interface SavedMeasurementNote {
  attachmentUrl?: string;
  attachments?: SavedMeasurementNoteAttachment[];
  content: string;
  mood: string;
  type: string;
}

export interface SavedMeasurementNoteAttachment {
  filename: string;
  url: string;
}

export const MOODS: Mood[] = [{
  id: MoodId.GREAT,
  text: 'GREAT',
  emoji: '😁',
}, {
  id: MoodId.HAPPY,
  text: 'HAPPY',
  emoji: '😊',
}, {
  id: MoodId.GOOD,
  text: 'GOOD',
  emoji: '😉',
}, {
  id: MoodId.NORMAL,
  text: 'NORMAL',
  emoji: '🙂',
}, {
  id: MoodId.OKAY,
  text: 'OKAY',
  emoji: '😐',
}, {
  id: MoodId.NOT_GOOD,
  text: 'NOT_GOOD',
  emoji: '😟',
}, {
  id: MoodId.BAD,
  text: 'BAD',
  emoji: '😔',
}, {
  id: MoodId.AWFUL,
  text: 'AWFUL',
  emoji: '😣',
}, {
  id: MoodId.NONE,
  text: 'NONE',
  emoji: ''
}];

export interface MeasurementGraphSettingLocalStorage {
  timePeriodSelected: TimePeriod;
  chartTypeSelected: ChartType;
  chartOrCalendarSelected: GraphType;
  trendLineActivated: boolean;
}

export interface BaseMeasurementSettings extends MeasurementGraphSettingLocalStorage {}

export class BaseMeasurementSettings {
  constructor() {
    this.timePeriodSelected = TimePeriod.DAY;
    this.chartTypeSelected = GraphType.LINE_CHART;
    this.chartOrCalendarSelected = GraphType.LINE_CHART;
    this.trendLineActivated = false;
  }
}

export enum MeasurementIdEnum {
  ACT_INDEX = 'ACT_INDEX',
  BLOODPRESSURE_SYS = 'BLOODPRESSURE_SYS',
  BLOODPRESSURE_DIA = 'BLOODPRESSURE_DIA',
  BLOODSUGAR = 'BLOODSUGAR',
  BLOODSUGAR_FASTING = 'BLOODSUGAR_FASTING',
  BLOODOXYGENLEVEL = 'BLOODOXYGENLEVEL',
  TEMPERATURE = 'TEMPERATURE',
  HEARTBEAT_REST = 'HEARTBEAT_REST',
  // todo inform client that it is missing
  // GLUCOSE = 'GLUCOSE',
  CHOLESTEROL_NL = 'CHOLESTEROL_NL',
  CHOLESTEROL_B = 'CHOLESTEROL_B',
  CHOLESTEROL_HDL = 'CHOLESTEROL_HDL',
  CHOLESTEROL_LDL = 'CHOLESTEROL_LDL',
  CHOLESTEROL_RATIO = 'CHOLESTEROL_RATIO',
  HEMOGLOBIN = 'HEMOGLOBIN',
  HEMOGLOBINE_A1C = 'HEMOGLOBINE_A1C',
  STEPS_STEPS = 'STEPS_STEPS',
  STEPS_DISTANCE = 'STEPS_DISTANCE',
  STAIRS = 'STAIRS',
  CYCLING_DISTANCE = 'CYCLING_DISTANCE',
  BMI = 'BMI',
  FAT = 'FAT',
  CALORIES_IN = 'CALORIES_IN',
  CALORIES_OUT = 'CALORIES_OUT',
  WEIGHT = 'WEIGHT',
  SLEEP_TOTAL = 'SLEEP_TOTAL',
  ABDOMINAL_CIRCUMFERENCE = 'ABDOMINAL_CIRCUMFERENCE',
  GRIP_STRENGTH = 'GRIP_STRENGTH',
  INSPIRATORY_PRESSURE = 'INSPIRATORY_PRESSURE',
  VO2_MAX = 'VO2_MAX',
  TRUNK_STABILITY_PUSH_UP = 'TRUNK_STABILITY_PUSH_UP',
  DEEP_SQUAT = 'DEEP_SQUAT',
  ROTARY_STABILITY = 'ROTARY_STABILITY',
  IN_LINE_LUNGE = 'IN_LINE_LUNGE',
  TOTAL_FMS_SCORE = 'TOTAL_FMS_SCORE',
  ASLR = 'ASLR',
  HURDLE_STEP = 'HURDLE_STEP',
  SHOULDER_MOBILITY = 'SHOULDER_MOBILITY',
  APO_B = 'APO_B',
  HS_CRP = 'HS_CRP',
  TRIGLYCERIDE = 'TRIGLYCERIDE',
  C_PEPTIDE_FASTING = 'C_PEPTIDE_FASTING',
  BODY_FUNCTIONS = 'BODY_FUNCTIONS',
  MENTAL_WELL_BEING = 'MENTAL_WELL_BEING',
  EXISTENTIAL_MEANING = 'EXISTENTIAL_MEANING',
  QUALITY_OF_LIFE = 'QUALITY_OF_LIFE',
  PARTICIPATION = 'PARTICIPATION',
  DAILY_FUNCTIONING = 'DAILY_FUNCTIONING',
  CUSTOM = 'CUSTOM',
  ATALMEDIAL = 'ATALMEDIAL'
}

export const MEASUREMENT_TYPES: { [id: string]: MeasurementType } = {
  /** Medical Measurements */
  /** Medical Measurements */
  /** Medical Measurements */
  ACT_INDEX: {
    id: MeasurementIdEnum.ACT_INDEX,
    measurementCategory: MeasurementCategory.MEDICAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.HIGHER_IS_BETTER,
    unit: '',
    icon: 'icn_pressure.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 0,
    formatValueStringFunction: null
  },
  BLOODPRESSURE_SYS: {
    id: MeasurementIdEnum.BLOODPRESSURE_SYS,
    measurementCategory: MeasurementCategory.MEDICAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.MIDDLE_IS_BETTER,
    unit: 'mmHg',
    icon: 'icn_pressure.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null
  },
  BLOODPRESSURE_DIA: {
    id: MeasurementIdEnum.BLOODPRESSURE_DIA,
    measurementCategory: MeasurementCategory.MEDICAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.MIDDLE_IS_BETTER,
    unit: 'mmHg',
    icon: 'icn_pressure.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null
  },
  BLOODSUGAR: {
    id: MeasurementIdEnum.BLOODSUGAR,
    measurementCategory: MeasurementCategory.MEDICAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.MIDDLE_IS_BETTER,
    unit: 'mg/dl',
    icon: 'icn_bloodsugar.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null
  },
  BLOODSUGAR_FASTING: {
    id: MeasurementIdEnum.BLOODSUGAR_FASTING,
    measurementCategory: MeasurementCategory.MEDICAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.MIDDLE_IS_BETTER,
    unit: 'mmol/l',
    icon: 'icn_bloodsugar_fasting.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null
  },
  BLOODOXYGENLEVEL: {
    id: MeasurementIdEnum.BLOODOXYGENLEVEL,
    measurementCategory: MeasurementCategory.MEDICAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.HIGHER_IS_BETTER,
    unit: '%',
    icon: 'icn_blood_oxygen.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 0,
    formatValueStringFunction: null
  },
  CHOLESTEROL_B: {
    id: MeasurementIdEnum.CHOLESTEROL_B,
    measurementCategory: MeasurementCategory.MEDICAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.LOWER_IS_BETTER,
    unit: 'mmol/l',
    icon: 'icn_cholesterol.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null,
  },
  CHOLESTEROL_NL: {
    id: MeasurementIdEnum.CHOLESTEROL_NL,
    measurementCategory: MeasurementCategory.MEDICAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.LOWER_IS_BETTER,
    unit: 'mmol/l',
    icon: 'icn_cholesterol.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null,
  },
  CHOLESTEROL_HDL: {
    id: MeasurementIdEnum.CHOLESTEROL_HDL,
    measurementCategory: MeasurementCategory.MEDICAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.HIGHER_IS_BETTER,
    unit: 'mmol/l',
    icon: 'icn_cholesterol_hdl.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null,
  },
  CHOLESTEROL_LDL: {
    id: MeasurementIdEnum.CHOLESTEROL_LDL,
    measurementCategory: MeasurementCategory.MEDICAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.LOWER_IS_BETTER,
    unit: 'mmol/l',
    icon: 'icn_cholesterol_ldl.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null,
  },
  CHOLESTEROL_RATIO: {
    id: MeasurementIdEnum.CHOLESTEROL_RATIO,
    measurementCategory: MeasurementCategory.MEDICAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.LOWER_IS_BETTER,
    unit: 'ratio',
    icon: 'icn_cholesterol_ratio.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null,
  },
  // todo inform client that glucose is missing in measurementTypeKey enum
  // GLUCOSE: {
  //   id: 'GLUCOSE',
  //   measurementCategory: MeasurementCategory.MEDICAL_MEASUREMENTS,
  //   qualityType: MeasurementQualityType.MIDDLE_IS_BETTER,
  //   unit: 'mmol/L',
  //   icon: 'icn_glucose.svg',
  //   allowNegativeValues: false,
  //   roundValueToDecimals: 1,
  //   formatValueStringFunction: null,
  // },
  HEMOGLOBIN: {
    id: MeasurementIdEnum.HEMOGLOBIN,
    measurementCategory: MeasurementCategory.MEDICAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.MIDDLE_IS_BETTER,
    unit: 'mg/dl',
    icon: 'icn_hemoglobine.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null,
  },
  HEMOGLOBINE_A1C: {
    id: MeasurementIdEnum.HEMOGLOBINE_A1C,
    measurementCategory: MeasurementCategory.MEDICAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.LOWER_IS_BETTER,
    unit: 'mmol/mol',
    icon: 'icn_hemoglobine_a1c.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null,
  },
  /** Lifestyle Measurements */
  /** Lifestyle Measurements */
  /** Lifestyle Measurements */
  ABDOMINAL_CIRCUMFERENCE: {
    id: MeasurementIdEnum.ABDOMINAL_CIRCUMFERENCE,
    unit: 'cm',
    measurementCategory: MeasurementCategory.LIFESTYLE_MEASUREMENTS,
    qualityType: MeasurementQualityType.MIDDLE_IS_BETTER,
    icon: 'icn_waist.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null,
  },
  BMI: {
    id: MeasurementIdEnum.BMI,
    measurementCategory: MeasurementCategory.LIFESTYLE_MEASUREMENTS,
    qualityType: MeasurementQualityType.MIDDLE_IS_BETTER,
    unit: 'kg/m²',
    icon: 'icn_weight.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null,
  },
  CALORIES_IN: {
    id: MeasurementIdEnum.CALORIES_IN,
    measurementCategory: MeasurementCategory.LIFESTYLE_MEASUREMENTS,
    qualityType: MeasurementQualityType.MIDDLE_IS_BETTER,
    unit: 'kcal',
    icon: 'icn_calories_in.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 0,
    formatValueStringFunction: null,
  },
  CALORIES_OUT: {
    id: MeasurementIdEnum.CALORIES_OUT,
    measurementCategory: MeasurementCategory.LIFESTYLE_MEASUREMENTS,
    qualityType: MeasurementQualityType.MIDDLE_IS_BETTER,
    unit: 'kcal',
    icon: 'icn_calories_out.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 0,
    formatValueStringFunction: null,
  },
  CYCLING_DISTANCE: {
    id: MeasurementIdEnum.CYCLING_DISTANCE,
    measurementCategory: MeasurementCategory.LIFESTYLE_MEASUREMENTS,
    qualityType: MeasurementQualityType.HIGHER_IS_BETTER,
    unit: 'km',
    icon: 'icn_cycling.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 2,
    formatValueStringFunction: null,
  },
  FAT: {
    id: MeasurementIdEnum.FAT,
    measurementCategory: MeasurementCategory.LIFESTYLE_MEASUREMENTS,
    qualityType: MeasurementQualityType.MIDDLE_IS_BETTER,
    unit: '%',
    icon: 'icn_fat_index.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null,
  },
  HEARTBEAT_REST: {
    id: MeasurementIdEnum.HEARTBEAT_REST,
    measurementCategory: MeasurementCategory.LIFESTYLE_MEASUREMENTS,
    qualityType: MeasurementQualityType.MIDDLE_IS_BETTER,
    unit: 'BPM',
    icon: 'icn_pulse.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 0,
    formatValueStringFunction: null,
  },
  SLEEP_TOTAL: {
    id: MeasurementIdEnum.SLEEP_TOTAL,
    unit: 'h',
    measurementCategory: MeasurementCategory.LIFESTYLE_MEASUREMENTS,
    qualityType: MeasurementQualityType.MIDDLE_IS_BETTER,
    icon: 'icn_sleep.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 2,
    formatValueStringFunction: null,
    timeAsDuration: true
  },
  STAIRS: {
    id: MeasurementIdEnum.STAIRS,
    measurementCategory: MeasurementCategory.LIFESTYLE_MEASUREMENTS,
    qualityType: MeasurementQualityType.HIGHER_IS_BETTER,
    unit: 'floors',
    icon: 'icn_general_stairs.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 0,
    formatValueStringFunction: null,
  },
  STEPS_DISTANCE: {
    id: MeasurementIdEnum.STEPS_DISTANCE,
    measurementCategory: MeasurementCategory.LIFESTYLE_MEASUREMENTS,
    qualityType: MeasurementQualityType.HIGHER_IS_BETTER,
    unit: 'km',
    icon: 'icn_steps.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 0,
    formatValueStringFunction: null,
  },
  STEPS_STEPS: {
    id: MeasurementIdEnum.STEPS_STEPS,
    measurementCategory: MeasurementCategory.LIFESTYLE_MEASUREMENTS,
    qualityType: MeasurementQualityType.HIGHER_IS_BETTER,
    unit: 'steps',
    icon: 'icn_steps.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 0,
    formatValueStringFunction: null,
  },
  TEMPERATURE: {
    id: MeasurementIdEnum.TEMPERATURE,
    measurementCategory: MeasurementCategory.MEDICAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.MIDDLE_IS_BETTER,
    unit: '°C',
    icon: 'icn_temperature.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null
  },
  WEIGHT: {
    id: MeasurementIdEnum.WEIGHT,
    measurementCategory: MeasurementCategory.LIFESTYLE_MEASUREMENTS,
    qualityType: MeasurementQualityType.MIDDLE_IS_BETTER,
    unit: 'kg',
    icon: 'icn_weight.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null,
  },
  /** Physical Measurements */
  /** Physical Measurements */
  /** Physical Measurements */
  ASLR: {
    id: MeasurementIdEnum.ASLR,
    unit: '',
    measurementCategory: MeasurementCategory.PHYSICAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.HIGHER_IS_BETTER,
    icon: 'icn_aslr.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 0,
    formatValueStringFunction: null,
  },
  DEEP_SQUAT: {
    id: MeasurementIdEnum.DEEP_SQUAT,
    unit: '',
    measurementCategory: MeasurementCategory.PHYSICAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.HIGHER_IS_BETTER,
    icon: 'icn_deep_squat.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 0,
    formatValueStringFunction: null,
  },
  GRIP_STRENGTH: {
    id: MeasurementIdEnum.GRIP_STRENGTH,
    unit: 'kg',
    measurementCategory: MeasurementCategory.PHYSICAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.HIGHER_IS_BETTER,
    icon: 'icn_squeezing.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null,
  },
  HURDLE_STEP: {
    id: MeasurementIdEnum.HURDLE_STEP,
    unit: '',
    measurementCategory: MeasurementCategory.PHYSICAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.HIGHER_IS_BETTER,
    icon: 'icn_hurdle_step.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 0,
    formatValueStringFunction: null,
  },
  IN_LINE_LUNGE: {
    id: MeasurementIdEnum.IN_LINE_LUNGE,
    unit: '',
    measurementCategory: MeasurementCategory.PHYSICAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.HIGHER_IS_BETTER,
    icon: 'icn_inline_lunge.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null,
  },
  INSPIRATORY_PRESSURE: {
    id: MeasurementIdEnum.INSPIRATORY_PRESSURE,
    unit: 'cmH₂O',
    measurementCategory: MeasurementCategory.PHYSICAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.LOWER_IS_BETTER,
    icon: 'icn_inhaling.svg',
    allowNegativeValues: true,
    roundValueToDecimals: 1,
    formatValueStringFunction: null,
  },
  ROTARY_STABILITY: {
    id: MeasurementIdEnum.ROTARY_STABILITY,
    unit: '',
    measurementCategory: MeasurementCategory.PHYSICAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.HIGHER_IS_BETTER,
    icon: 'icn_rotary_stability.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null,
  },
  SHOULDER_MOBILITY: {
    id: MeasurementIdEnum.SHOULDER_MOBILITY,
    unit: '',
    measurementCategory: MeasurementCategory.PHYSICAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.HIGHER_IS_BETTER,
    icon: 'icn_shoulder_mobility.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null,
  },
  TOTAL_FMS_SCORE: {
    id: MeasurementIdEnum.TOTAL_FMS_SCORE,
    unit: '',
    measurementCategory: MeasurementCategory.PHYSICAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.HIGHER_IS_BETTER,
    icon: 'icn_fms.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null,
  },
  TRUNK_STABILITY_PUSH_UP: {
    id: MeasurementIdEnum.TRUNK_STABILITY_PUSH_UP,
    unit: 'push ups',
    measurementCategory: MeasurementCategory.PHYSICAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.HIGHER_IS_BETTER,
    icon: 'icn_trunk_stability_push_up.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null,
  },
  VO2_MAX: {
    id: MeasurementIdEnum.VO2_MAX,
    unit: 'mL/(kg·min)',
    measurementCategory: MeasurementCategory.PHYSICAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.HIGHER_IS_BETTER,
    icon: 'icn_v2o_max.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null,
  },
  /** Blood value measurements */
  /** Blood value measurements */
  /** Blood value measurements */
  APO_B: {
    id: MeasurementIdEnum.APO_B,
    measurementCategory: MeasurementCategory.BLOOD_MEASUREMENTS,
    qualityType: MeasurementQualityType.MIDDLE_IS_BETTER,
    unit: 'g/l',
    icon: 'icn_pressure.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 2,
    formatValueStringFunction: null
  },
  C_PEPTIDE_FASTING: {
    id: MeasurementIdEnum.C_PEPTIDE_FASTING,
    unit: 'nmol/l',
    measurementCategory: MeasurementCategory.BLOOD_MEASUREMENTS,
    qualityType: MeasurementQualityType.MIDDLE_IS_BETTER,
    icon: 'icn_blood_value_sober.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null,
  },
  HS_CRP: {
    id: MeasurementIdEnum.HS_CRP,
    unit: 'mg/l',
    measurementCategory: MeasurementCategory.BLOOD_MEASUREMENTS,
    qualityType: MeasurementQualityType.LOWER_IS_BETTER,
    icon: 'icn_blood_value.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null,
  },
  TRIGLYCERIDE: {
    id: MeasurementIdEnum.TRIGLYCERIDE,
    unit: 'mmol/l',
    measurementCategory: MeasurementCategory.BLOOD_MEASUREMENTS,
    qualityType: MeasurementQualityType.LOWER_IS_BETTER,
    icon: 'icn_blood_value.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null,
  },
  /** Mental measurements */
  /** Mental measurements */
  /** Mental measurements */
  BODY_FUNCTIONS: {
    id: MeasurementIdEnum.BODY_FUNCTIONS,
    unit: '',
    measurementCategory: MeasurementCategory.MENTAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.HIGHER_IS_BETTER,
    icon: 'icn_body.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 1,
    formatValueStringFunction: null,
  },
  DAILY_FUNCTIONING: {
    id: MeasurementIdEnum.DAILY_FUNCTIONING,
    unit: '',
    measurementCategory: MeasurementCategory.MENTAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.HIGHER_IS_BETTER,
    icon: 'icn_daily.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 0,
    formatValueStringFunction: null,
  },
  EXISTENTIAL_MEANING: {
    id: MeasurementIdEnum.EXISTENTIAL_MEANING,
    unit: '',
    measurementCategory: MeasurementCategory.MENTAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.HIGHER_IS_BETTER,
    icon: 'icn_meaning.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 0,
    formatValueStringFunction: null,
  },
  MENTAL_WELL_BEING: {
    id: MeasurementIdEnum.MENTAL_WELL_BEING,
    unit: '',
    measurementCategory: MeasurementCategory.MENTAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.HIGHER_IS_BETTER,
    icon: 'icn_mental.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 0,
    formatValueStringFunction: null,
  },
  PARTICIPATION: {
    id: MeasurementIdEnum.PARTICIPATION,
    unit: '',
    measurementCategory: MeasurementCategory.MENTAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.HIGHER_IS_BETTER,
    icon: 'icn_participation.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 0,
    formatValueStringFunction: null,
  },
  QUALITY_OF_LIFE: {
    id: MeasurementIdEnum.QUALITY_OF_LIFE,
    unit: '',
    measurementCategory: MeasurementCategory.MENTAL_MEASUREMENTS,
    qualityType: MeasurementQualityType.HIGHER_IS_BETTER,
    icon: 'icn_quality.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 0,
    formatValueStringFunction: null,
  },
  CUSTOM: {
    id: MeasurementIdEnum.CUSTOM,
    unit: '',
    measurementCategory: MeasurementCategory.CUSTOM_MEASUREMENTS,
    qualityType: MeasurementQualityType.HIGHER_IS_BETTER,
    icon: 'icn_custom_measurement.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 0,
    formatValueStringFunction: null
  },
  ATALMEDIAL: {
    id: MeasurementIdEnum.ATALMEDIAL,
    unit: '',
    measurementCategory: MeasurementCategory.CUSTOM_MEASUREMENTS,
    qualityType: MeasurementQualityType.HIGHER_IS_BETTER,
    icon: 'icn_custom_measurement.svg',
    allowNegativeValues: false,
    roundValueToDecimals: 2,
    formatValueStringFunction: null
  }
};
